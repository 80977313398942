
import { mapGetters, mapActions } from 'vuex';
import LoseWeightHero from './lose-weight-hero/lose-weight-hero';
import MainHeader from '@/components/static/main-header/main-header';
import MainFooter from '@/components/static/main-footer/main-footer';
import QuizDefault from '@/components/core/quiz-default/quiz-default';
import QuizProgressLanding from '@/components/core/quiz-progress/quiz-progress-landing.vue';

export default {
  components: {
    LoseWeightHero,
    MainHeader,
    MainFooter,
    QuizDefault,
    QuizProgressLanding,
  },
  props: {
    titleHero: {
      type: String,
      default: null,
    },
    quizVariant: {
      type: String,
      default: null,
    },
    hasDisclaimer: {
      type: Boolean,
      default: true,
    },
  },
  fetch() {
    const { params } = this.$route;
    const experimentVariantTour = this.getTourFromExperiment();

    if (this.checkTourUrl) {
      return;
    }

    if (this.quizVariant) {
      this.setQuizVariantAction(this.quizVariant);
    } else if (params.variant) {
      this.setQuizVariantAction(params.variant);
    } else if (experimentVariantTour) {
      this.setQuizVariantAction(experimentVariantTour);
    } else {
      this.setQuizVariantAction(this.QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY);
    }
  },
  computed: {
    ...mapGetters('quiz', ['getQuizCurrentIndex', 'getQuizVariant']),
    landingVariant() {
      const { params } = this.$route;

      if (params.variant === 'symptoms') {
        return this.$t('landingPage.symptoms');
      }

      if (
        [
          this.QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
          this.QUIZ_VARIANTS.PILATES_28_DAY_FLAT_BELLY,
          this.QUIZ_VARIANTS.FAKE_FIT_OVER_40,
          this.QUIZ_VARIANTS.PILATES_NOW_WEEKLY_2,
          this.QUIZ_VARIANTS.PILATES_NEW_LANDER,
          this.QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER,
          this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
          this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_UPDATED_TARGET_ZONE_STEP,
          this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_US,
          this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS,
          this.QUIZ_VARIANTS.PILATES_28_DAY_CHAIR_CHALLENGE,
          this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
          this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT,
          this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V1,
          this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V2,
        ].includes(this.getQuizVariant)
      ) {
        return this.$t('landingPage.bestBody');
      }

      return this.$t('landingPage');
    },
    landingPage() {
      return this.getQuizVariant === this.QUIZ_VARIANTS.DEFAULT ? null : this.landingVariant;
    },
    quiz() {
      const variant = this.getQuizVariant;
      return this.$t('landingPage.quizLanding')[variant] || {};
    },
    getVariantNewLandingQuiz() {
      const newLandingQuiz = this.$t(`landingPage.${this.getQuizVariant}`)?.newLandingQuiz;
      return newLandingQuiz || false;
    },
    checkTourUrl() {
      const tourUrls = [
        '/tour/body-nutrition',
        '/tour/pilates',
        '/tour/wall-pilates',
        '/tour/pilates-28-day-challenge',
        '/tour/pilates-28-day-chair-challenge',
        '/tour/pilates-28-day-flat-belly',
        '/tour/friends-special-offer',
        '/tour/pilates-now',
        '/tour/nutrition-keto',
        '/tour/nutrition-keto-meal',
        '/tour/nutrition-keto-weekly',
        '/tour/nutrition-keto-age',
        '/tour/nutrition-28-day-sugar-challenge',
        '/tour/nutrition-28-day-wl-challenge',
        '/tour/nutrition-weekly-start',
        '/tour/meds',
        '/tour/yoga-28-day-challenge',
        '/tour/yoga-menopause-challenge',
        '/tour/nutrition-keto-weekly-age',
        '/tour/pilates-somatic-28-day-challenge',
        '/tour/nutrition-keto-meal-weekly',
        '/tour/somatic-yoga',
        '/tour/somatic-exercises',
        '/tour/nutrition-monthly-generic-us',
        '/tour/nutrition-monthly-generic-others',
        '/tour/pilates-28-day-challenge-generic-us',
        '/tour/pilates-28-day-challenge-generic-others',
      ];

      const pathWithoutLastSlash = this.$route.path.replace(/\/$/, '');
      return tourUrls.includes(pathWithoutLastSlash);
    },
  },
  mounted() {
    this.setQuizReferrerLinkAction(location.pathname);
  },
  methods: {
    ...mapActions('quiz', [
      'setQuizVariantAction',
      'pushQuizAnswerToTheStoreAction',
      'setQuizReferrerLinkAction',
      'setDefaultStateValue',
      'setQuizCurrentIndexAction',
    ]),
    handleDefaultState(value) {
      this.pushQuizAnswerToTheStoreAction({ id: value.id, answer: value.answer });
      this.setDefaultStateValue(value);
      this.$router.push('/quiz/start');
      this.setQuizCurrentIndexAction(this.getQuizCurrentIndex + 1);
    },
  },
};
